$(document).ready(function () {

    // Hamburger
    $('.ham').click(function () {
        $(this).toggleClass('active');
        $('#overlay').toggleClass('open');
        $('.body').toggleClass('noscroll');
    });

    // p1 - Banner
    $('.p1-slider').slick({
        arrows: false,
        dots: true,
        autoplay: true
    });

    $('.p1-prev').on('click', function () {
        $('.p1-content .slick-prev').trigger('click');
    });

    $('.p1-next').on('click', function () {
        $('.p1-content .slick-next').trigger('click');
    });

    // p2 - Slider
    $('.p2-slider').slick({
        mobileFirst: true,
        infinite: true,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 3
            }
        }]
    });

    $('.p2-prev').on('click', function () {
        $('.p2-slider .slick-prev').trigger('click');
    });

    $('.p2-next').on('click', function () {
        $('.p2-slider .slick-next').trigger('click');
    });

    // p3 - Slider
    $('.p3-slider').slick({
        mobileFirst: true,
        infinite: true
    });

    $('.p3-prev').on('click', function () {
        $('.p3-slider .slick-prev').trigger('click');
    });

    $('.p3-next').on('click', function () {
        $('.p3-slider .slick-next').trigger('click');
    });

    // p4 - Slider
    $('.p4-slider').slick({
        mobileFirst: true,
        infinite: true,
        responsive: [{
            breakpoint: 991,
            infinite: true,
            settings: {
                slidesToShow: 2
            }
        }]
    });

    $('.p4-prev').on('click', function () {
        $('.p4-slider .slick-prev').trigger('click');
    });

    $('.p4-next').on('click', function () {
        $('.p4-slider .slick-next').trigger('click');
    });


    // gotop
    $('.gotop').click(function (e) {
        $('html,body').animate({
            scrollTop: $('body').offset().top
        }, 800);
        e.preventDefault();
    });

    $('.gotop').hover(function (e) {
        $(this).toggleClass('top-hover');
    });


    MasonryInit();
    // MasonryInit();

    function MasonryInit() {
        var windowsize = $(window).width();

        if (windowsize > 767) {
            Masonit();
            // pc 執行初始
        }
    }

    function Masonit() {
        $(".grid-masonry").imagesLoaded(function () {
            $(".grid-masonry").masonry({
                isFitWidth: true
            });
        });
    }



});